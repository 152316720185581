<template>
  <div class="shop">
    <!-- 标题 -->
    <!-- <div>
      <van-sticky>
        <van-nav-bar title="便民商店" />
      </van-sticky>
    </div> -->
    <!-- tab栏 -->
    <div class="information">
      <div>
        <div class="integral"
          v-for="item in list"
          :key="item">
          <div class="integralName">
            <span>名 称: {{item.CateName}}</span>
            <span>商品类别: {{item.Name}}</span>
          </div>
          <div class="integralParty">描述: {{item.Describe}}</div>
          <div class="updataButton">
            <span class="centertime"
              style="color: #333">

              <van-button type="info"
                round
                style="width:60px;"
                @click="updataGoodsType(item.SortId)"
                size="mini">编辑</van-button>
              <van-button type="danger"
                round
                style="width:60px;"
                @click="deletGoodsType(item)"
                size="mini">删除</van-button>
            </span>
          </div>
        </div>
      </div>
      <!-- <van-list v-model="loading"
        :finished="finished"
        offset="50"
        @load="onLoad">
        <div class="center"
          v-for="item in list"
          :key="item.index">
          <div class="shopTitle van-ellipsis">{{ item.Title }}</div>
          <div class="Content"
            style="padding: 8px 10px">
            <div style="display: inline-block"
              class="ContentA">
              <van-image style="width: 100%; height: 85px"
                :src="item.ShopImgUrl">
                <template style="width: 100%; height: 85px"
                  v-slot:error>
                  <img src="http://lccgzapi.zhsq.cloud//upload/images/2021/11/b6b3ab86-df3.png"
                    alt="" />
                </template>
              </van-image>
            </div>
            <div style="display: inline-block;"
              class="ContentB">
              <span style="color: #333"
                class="van-multi-ellipsis--l3">{{ item.CateName }}</span>
              <br />
              <span style="font-size: 12px; color: #333">{{ item.Name }}</span>
              <br />
              <span class="centertime"
                style="color: #333">
                <van-icon name="manager" />{{ item.Charger }}
                <van-icon name="phone" /><a :href="'tel:' + item.Mobile"
                  style="color:black;">{{ item.Mobile }}</a>
              </span>
            </div>
            <div style="display: inline-block;width: 95%;text-align: right;"
              class="ContentB">
              <span class="centertime"
                style="color: #333">

                <van-button type="info"
                  round
                  style="width:60px;"
                  @click="shopUpdata(item.ShopId)"
                  size="mini">编辑</van-button>
              </span>
            </div>
          </div>
        </div>
      </van-list> -->
      <!-- 没有更多数据了 -->
      <div v-if="hidden"
        class="hidden">───── 没有更多数据了 ─────</div>
      <div>
        <div style="width: 95%; bottom: 0; position: fixed; padding: 10px">
          <van-button round
            type="primary"
            size="normal"
            style="
              text-center: right;
              font-size: 16px;
              width: 100%;
              float: right;
            "
            @click="addGoodsType(1)">新增</van-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Dialog } from 'vant';
import { Toast } from "vant";
import { WxGetMyGoodsSortList, WxDeleteGoodsSort } from "@/api/RealInfo";
import { setOpenId, getOpenId } from "@/utils/auth";
Vue.use(Toast);
export default {
  data () {
    return {
      riKind: 0, //tab栏
      list: {}, //资讯列表
      listfrom: {
        page: 1,
        limit: 10,
      }, //查询传参
      search: "", //搜索内容
      hidden: true, //没有更多数据了
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
    };
  },
  methods: {
    // 员工信息
    entYuanGong (row) {
      console.log(row);
      this.$router.push({
        // name: "ygList",
        path: "/shop/myShop/ygList/" + row.ShopId + "/" + 2,
        // query: { seId: row.EId, ekind: 1 },
      });

    },
    // 商品列表
    shopGoodsList (row) {
      console.log(row);
      this.$router.push({
        path: "/shop/myShop/goodsList/" + row,
      });
    },
    //新增商品分类
    addGoodType () {
      this.$router.push({
        path: "/shop/myShop/goodsTypeList/" + row.ShopId,
      });
    },
    //新增分类
    addGoodsType (row) {
      this.$router.push({
        name: "goodsTypeListAdd",
        query: { SortId: 0, add: 1, shopId: this.$route.params.shopId },
      });
    },
    //编辑分类
    updataGoodsType (row) {
      this.$router.push({
        name: "goodsTypeListAdd",
        query: { SortId: row, add: 2, shopId: this.$route.params.shopId },
      });
    },
    //重新绑定
    shopBangDing (shopId) {
      this.$router.push({
        name: "addShop",
        query: { shopId: shopId, add: 3 },
      });
    },
    //编辑
    shopUpdata (shopId) {
      console.log(shopId);
      this.$router.push({
        name: "addShop",
        query: { shopId: shopId, add: 2 },
      });
    },
    // 删除
    deletGoodsType (row) {
      Dialog.confirm({
        title: '提示',
        message:
          '确定要删除该分类吗',
      })
        .then(() => {
          // 确认
          this.getdeletGoodsType(row)
        })
        .catch(() => {
          // 取消
          console.log(111);
        });
    },
    getdeletGoodsType: function (row) {
      console.log(row);
      WxDeleteGoodsSort({ openID: getOpenId(), SortId: row.SortId, ShopId: row.ShopId })
        .then((res) => {
          if (res.data.code == 0) {
            Toast.success("删除成功");
            this.getList()
          }
        })

    },
    // 获取分页列表
    getList () {
      WxGetMyGoodsSortList({ openID: getOpenId(), shopId: this.$route.params.shopId })
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              // this.hidden = true;
              this.finished = true;
              this.list = {};
            } else {
              this.list = res.data.data;
              this.total = res.data.count;
              this.finished = true;
              // this.hidden = false;
              for (var i = 0; i < this.list.length; i++) {
                var item = this.list[i].IssTime;
                item = this.dataFormat(item);
                this.list[i].IssTime = item;
                if (this.list[i].ShopImgUrl != "rejected") {
                  this.list[i].ShopImgUrl =
                    "https://cuiyunapi.dexian.ren//upload/images/2021/7/873b94ff-81d.png";
                }
              }
            }
          }
        })
        .catch(() => { });
    },

    // 下拉刷新
    onLoad () {
      console.log(123);
      this.listfrom.page = this.listfrom.page + 1;
      this.getList();
    },
    // tan点击切换事件
    onClick (e) {
      this.riKind = e;
      this.listfrom.page = 1;
      this.getList();
    },
    // 搜索
    onSearch () {
      this.listfrom.page = 1;
      this.getList();
    },
    // 时间格式化
    dataFormat (value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
  },
  mounted () {
    this.getList();
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
  },
};
</script>
<style>
.shopTitle {
  color: #333;
  padding: 10px;
}
.shop .information .center {
  border-bottom: none;
  padding: 0px;
  border-radius: 5px;
}
.shop .center {
  width: 92%;
  margin: 10px auto;
  background: rgb(246, 243, 243);
}
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
</style>
<style scoped>
.integral {
  width: 90%;
  margin: 10px 5%;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 5px 17px 1px rgb(0 0 0 / 7%);
}
.integralName {
  padding: 15px 0px 8px 15px;
  font-size: 15px;
  overflow: hidden;
}
.integralName span:nth-child(1) {
  float: left;
}
.integralName span:nth-child(2) {
  float: right;
  margin-right: 15px;
}
.integralParty {
  padding-left: 15px;
  padding-bottom: 15px;
  font-size: 14px;
  color: rgb(172, 167, 167);
}
.updataButton {
  display: inline-block;
  width: 95%;
  text-align: right;
  margin-bottom: 10px;
}
</style>